.root {
  background-color: black;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  position: relative;

  height: 100%;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}
.contents {
  display: grid;
  grid-template-areas:
    "video"
    "controls";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0;
  /* transition: grid-template-rows 0.5s, grid-template-columns 0.5s, -ms-grid-rows 0.5s,
    -ms-grid-columns 0.5s;
  transition-timing-function: cubic-bezier(0.26, 0, 0.2, 1); */
  position: relative;
}
.videoArea {
  display: grid;
  grid-area: video;
  overflow: hidden;
  width: 100%;
  grid-template-areas:
    "player"
    "timeline";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0;
  position: relative;
}
.controlArea {
  display: grid;
  flex-shrink: 0;
  grid-area: controls;
  grid-template-areas:
    "progressBar"
    "options";
  grid-template-columns: 1fr;
  grid-template-rows: var(--heightProgressBar) 1fr;
  /* var(--heightProgressBar) */
  /* height: 120px; */
  /* margin: 8px auto 0; */
  /* max-width: 1920px; */
  position: relative;
  width: 100%;
  z-index: 2;
}
.titleContainer {
  z-index: 2;
  position: absolute;
  padding: 8px;
}
.titleName {
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 2px;
  border-radius: 5px;
}
.fatalErrorContainer {
  z-index: 2;
  position: absolute;
  padding: 8px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.fatalErrorTitle {
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 2px;
  border-radius: 5px;
}
.videoColumnRow {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-area: player;
}
.videoColumnWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
}
.videoColumn {
  padding: 0px;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}
.gridArea {
  display: flex;
  flex: 1;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.DraggableGrid {
  position: relative;
}
.background {
  background-color: #030e16;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}
.cell {
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
}
.cameraCell {
  background-color: var(--secondaryColor);
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.videoPlayer {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
}
.zoomArea {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.panZoomArea {
  height: 100%;
  width: 100%;
  touch-action: none;
}
.content {
  display: flex;
  flex-direction: column;
}
.previousFrame {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.videoContainer {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.video {
  opacity: 1;
  transition-delay: 0.1s;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 45%;
  z-index: 5;
}
.noVideoIcon {
  position: absolute;
  top: 45%;
  left: 0;
  z-index: 5;
}
.loadingEvents {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-25%);
}
.progressBarContainer {
  position: relative;
  display: flex;
  width: 100%;
  transition: 0.3s;
  background: var(--hoverBackgroundColor);
  cursor: pointer;
  height: var(--heightProgressBar);
  align-items: center;
  justify-content: center;
  grid-area: progressBar;
}
.progressBar_filled {
  position: absolute;
  left: 0px;
  width: var(--progressBarPercent);
  background: var(--primaryColorLight);
  height: var(--heightProgressBar);
  transition: all 0.3s cubic-bezier(0, 0.55, 0.24, 1), border 0ms cubic-bezier(0, 0.55, 0.24, 1);
  z-index: 1;
}

.progressBar_buffer {
  position: absolute;
  background: var(--progressBufferColor);
  height: var(--heightProgressBar);
  /* transition: all 0.3s cubic-bezier(0, 0.55, 0.24, 1), border 0ms cubic-bezier(0, 0.55, 0.24, 1); */
  pointer-events: none;
}
.progressBar_verticalLine {
  background-color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  transition: all 0.3s cubic-bezier(0, 0.55, 0.24, 1), border 0ms cubic-bezier(0, 0.55, 0.24, 1);
  width: 2px;
  position: absolute;
  left: var(--progressBarPercent);
  height: var(--heightProgressBar);
  color: black;
  z-index: 1;
  pointer-events: none;
}
.progressBar_event {
  position: absolute;
  height: var(--heightEvent);
  pointer-events: none;
  border-radius: 5px;
  z-index: 20;
}
.progressBarContainer:hover .progressBar_filled {
  /* height: 20px; */
}
.hover_time {
  z-index: 4;
  position: absolute;
  top: -30px;
  left: var(--hoverProgressBarPosition);
  background-color: rgba(0, 0, 0, 0.8); /* Background color */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: none;
  transform: translateX(-50%);
}
.thumbnailImage {
  position: absolute;
  left: var(--hoverProgressBarPosition);
  transform: translateX(-50%);
  border-radius: 0.25rem;
  border: 2px solid white;
  aspect-ratio: var(--aspect-ratio);
}
.controlsContainer {
  grid-area: options;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  z-index: 2;
  justify-content: space-between;
}
.control_icons {
  display: flex;
  justify-content: flex-start;
}
.options_icons {
  display: flex;
  justify-content: flex-end;
}
.theIcons {
  color: white !important;

  &:hover {
    color: var(--secondaryColor) !important;
    background-color: var(--hoverBackgroundColor) !important;
  }
}
@keyframes scaleAndDisappear {
  0% {
    opacity: 0.6;
    transform: translate(-50%, -50%) scale(0.5);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
.iconWithAnimation {
  align-items: center;
  background: #000;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  animation: scaleAndDisappear 1s ease-out forwards;
  pointer-events: none;
  color: #fff;
}

.barWithoutVideo {
  position: absolute;
  background-color: black;
  z-index: 3;
  height: var(--heightProgressBar);
  opacity: 0.8;
  pointer-events: none;
}

.progressBar_lineTimestamp {
  background-color: whitesmoke;
  border: 2px solid black;
  width: 20px;
  position: absolute;
  height: var(--heightProgressBar);
  color: whitesmoke;
  z-index: 5;
  /* opacity: 0.8; */
  top: 0px;
}
.progressBar_lineTimestamp_left {
  composes: progressBar_lineTimestamp;
  left: 0px;
}
.progressBar_lineTimestamp_right {
  composes: progressBar_lineTimestamp;
  right: 0px;
}
.progressBar_lineTimestamp_container {
  position: relative;
  height: var(--heightProgressBar);
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressBar_lineTimestamp_container_hover_time {
  z-index: 4;
  position: absolute;
  top: -34px;
  background-color: rgba(255, 255, 255, 0.9); /* Background color */
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
}
.progressBar_timestamp_draggable {
  position: absolute;
  /* background: var(--secondaryColor); */
  /* opacity: 0.3; */
  height: var(--heightProgressBar);
  z-index: 1;
  background: linear-gradient(
    45deg,
    transparent 49.5%,
    var(--secondaryColor) 50%,
    var(--secondaryColor) 50%,
    transparent 50.5%
  );
  background-size: 10px 10px;
}
.timelineContainer {
  position: absolute;
  display: grid;
  width: 100%;
  /* background: blue; */
  grid-area: timeline;
  z-index: 2;
  bottom: 0;
}
.timeline {
  width: 100%;
  /* cursor: pointer; */
}
.timeline_buttons_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 4px; */
  flex: 1;
  position: relative;
}
.timeline_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondaryColor);
  z-index: 4;
  border-radius: 8%;
}
.timeline_button_container_button {
  &:hover {
    background-color: white !important;
  }
}
.timeline_button_container_textTime {
  padding-left: 4px;
  padding-right: 4px;
}

/* //////////////////////////////////////////// */
.timeline_Drag_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-bottom: 4px; */
  flex: 1;
  position: relative;
}
.Timeline {
  color: #818b92;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;

  height: 16px;
  cursor: grab;
}
.Timeline_dragging {
  composes: Timeline;
  cursor: grabbing;
}

.tick_long {
  background-color: gray;
  opacity: 1;
  position: absolute;
  width: 2px;
  height: 100%;
  bottom: 0;
}
.tick_short {
  background-color: gray;
  opacity: 1;
  position: absolute;
  width: 1px;
  height: 50%;
  bottom: 0;
}
.currentDate_button {
  margin-left: 8px;
  &:hover {
    background-color: gray !important;
  }
}
